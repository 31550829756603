import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import Container from '../../components/layout/Container';
import Text from '../../components/shared/Text';
import {franchiseChangeDisabledDate, isFranchiseChangeDisabled} from '../../utils/date';
import {FranchiseChangePaths} from '../paths';
import InfoBox from '../../components/shared/InfoBox';
import {Config} from '../../config';

const FranchiseChangeDisabled = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const startYearOfNextDisabledCycle = franchiseChangeDisabledDate.get('year') + 1;
  const beginOfNewYearAfterDisabledCycle = dayjs().month(0).date(1).year(startYearOfNextDisabledCycle + 1);

  useEffect(() => {
    if (!isFranchiseChangeDisabled()) {
      navigate(FranchiseChangePaths.index());
    }
  }, []);

  return (
    <Container background={'white'} flex={1}>
      <Text header={1}>{t('franchiseChange.title')}</Text>
      <InfoBox title={t('franchiseChange.disabled.title', {
        date: franchiseChangeDisabledDate.format(Config.dateFormatWithWords),
      })}
      >
        <Text size={'intro'}>
          {t('franchiseChange.disabled.text', {
            startYearOfNextDisabledCycle,
            startOfNewYear: beginOfNewYearAfterDisabledCycle.format(Config.dateFormatWithWords),
          })}
        </Text>
      </InfoBox>
    </Container>
  );
};

export default FranchiseChangeDisabled;
