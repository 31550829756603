import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import dayjs from 'dayjs';
import ReactMarkdown from 'react-markdown';
import Container from '../../components/layout/Container';
import Text from '../../components/shared/Text';
import {cancellationDisabledDate, cancellationEnabledDate, isCancellationDisabled} from '../../utils/date';
import {CancellationOverviewPath} from '../paths';
import InfoBox from '../../components/shared/InfoBox';
import {Config} from '../../config';

const CancellationDisabled = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isCancellationDisabled()) {
      navigate(CancellationOverviewPath);
    }
  }, []);

  return (
    <Container background={'white'} flex={1}>
      <Text header={1}>{t('cancellation.disabled.title')}</Text>
      <InfoBox title={t('cancellation.disabled.info.title', {
        date: cancellationDisabledDate.format(Config.dateFormatWithWords),
      })}
      >
        <Text size={'intro'}>
          <ReactMarkdown>
            {t('cancellation.disabled.info.text', {
              enabledDate: dayjs(cancellationEnabledDate)
                .format(Config.dateFormatWithWords),
              overviewUrl: CancellationOverviewPath,
            })}
          </ReactMarkdown>
        </Text>
      </InfoBox>
    </Container>
  );
};

export default CancellationDisabled;
