/* eslint-disable import/no-cycle */
import {action, computed, makeObservable, observable} from 'mobx';
import {RootStore} from './root-store';
import {ContactLanguage, PaymentMethod, PaymentRhythm, Person, Sex} from '../models/person';
import {IApiInsurance, IApiPerson, IIssue, PersonType} from '../models/document-request';
import {BaseInsurance} from '../models/base-insurance';
import i18n from '../i18n/i18n';
import {Config} from '../config';
import {AquilanaFunctionStore} from './aquilana-function-store';
import {startOfNextYear} from '../utils/date';

const STORAGE_KEY = 'franchise';

export class FranchiseChangeStore extends AquilanaFunctionStore {
  contactLanguage?: string = undefined;
  paymentMethod?: PaymentMethod = undefined;
  usePaymentMethodForCostSharing?: boolean = undefined;
  paymentRhythm?: PaymentRhythm = undefined;
  iban?: string = undefined;
  insuranceNumber?: string = undefined;

  constructor(rootStore: RootStore) {
    super(rootStore);

    makeObservable(this, {
      contactLanguage: observable,
      paymentMethod: observable,
      paymentRhythm: observable,
      usePaymentMethodForCostSharing: observable,
      iban: observable,
      insuranceNumber: observable,

      setContactLanguage: action,
      setPaymentMethod: action,
      setPaymentUseMethodForCostSharing: action,
      setPaymentRhythm: action,
      setIban: action,
      setInsuranceNumber: action,
      loadFromSession: action,
      saveToSession: action,
      convertPersonToIPersonRequest: action,

      hasBaseInsurance: computed,
    });
  }

  setContactLanguage(contactLanguage: string) {
    this.contactLanguage = contactLanguage;
  }

  setPaymentMethod(paymentMethod: PaymentMethod) {
    this.paymentMethod = paymentMethod;
  }

  setPaymentUseMethodForCostSharing(usePaymentMethodForCostSharing: boolean) {
    this.usePaymentMethodForCostSharing = usePaymentMethodForCostSharing;
  }

  setPaymentRhythm(paymentRhythm: PaymentRhythm) {
    this.paymentRhythm = paymentRhythm;
  }

  setIban(iban: string) {
    this.iban = iban;
  }

  setInsuranceNumber(insuranceNumber: string) {
    this.insuranceNumber = insuranceNumber;
  }

  saveToSession() {
    const data = this.getInsuranceData();
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  }

  loadFromSession(): boolean {
    const storedData = localStorage.getItem(STORAGE_KEY);

    if (storedData) {
      const data: IApiInsurance = JSON.parse(storedData);
      const models = data.people.map((p) => {
        const person = Person.fromJSON(p);

        if (person.type === PersonType.POLICY_HOLDER) {
          this.setCity(this.rootStore.cityStore.getByUrn(p.city));
          this.setAddress(p.address1);
        }

        this.setInsuranceNumber(p.insuranceNo ?? '');

        if (p.baseInsurance?.baseInsuranceRate) {
          const rate = this.rootStore.baseInsuranceRateStore.getByUrn(p.baseInsurance.baseInsuranceRate);
          const franchiseGroup = this.rootStore.franchiseGroupStore.getById(rate?.franchiseGroupId ?? 1);
          const baseInsurance = BaseInsurance.fromJSON(p.baseInsurance, rate, franchiseGroup);
          if (rate) {
            if (p.baseInsurance.oldBaseInsuranceRate) {
              const oldRate = this.rootStore.baseInsuranceRateStore.getByUrn(p.baseInsurance.oldBaseInsuranceRate);
              baseInsurance.setOldRate(oldRate);
              baseInsurance.setOldFranchiseGroup(this.rootStore.franchiseGroupStore.getById(
                oldRate?.franchiseGroupId ?? 1
              ));
            }
            baseInsurance.setRate(rate);
            person.setBaseInsurance(baseInsurance);
          }
        }

        return person;
      });

      const validModels = models.filter((p) => p.baseInsurance && p.firstName && p.lastName);

      if (validModels.length) {
        this.setModels(validModels);
      }

      return validModels.length > 0;
    }

    return false;
  }

  reset(): void {
    this.models = [Person.create({type: PersonType.POLICY_HOLDER})];
    this.currentIndex = 0;
    this.city = undefined;
    this.address = undefined;
    this.email = undefined;
    this.phone = undefined;
    this.contactLanguage = undefined;
    this.paymentMethod = undefined;
    this.usePaymentMethodForCostSharing = undefined;
    this.paymentRhythm = undefined;
    this.iban = undefined;
    this.insuranceNumber = undefined;
    localStorage.removeItem(STORAGE_KEY);
  }

  convertPersonToIPersonRequest(person: Person, index: number, includeUUID: boolean): IApiPerson {
    const firstName = person.firstName?.length
      ? person.firstName
      : i18n.t('personCard.namePlaceholder', {number: index + 1});
    return {
      uuid: includeUUID ? person.uuid : undefined,
      firstName,
      lastName: !includeUUID ? person.lastName || '' : '',
      address1: !includeUUID ? this.address || '' : '',
      address2: '',
      city: this.city ? `/api/cities/${this.city.id}` : '',
      phone: !includeUUID ? this.phone || '' : '',
      mobile: '',
      email: !includeUUID ? this.email || '' : '',
      birthday: person.birthday?.format(Config.apiDateFormat) ?? '',
      country: person.residenceInSwitzerland ? 'CH' : '',
      sex: person.sex || Sex.MALE,
      language: this.contactLanguage || ContactLanguage.DE,
      insuranceNo: person.insuranceNo || '',
      type: person.type,
      premiumReductionBaseInsurance: this.getPremiumReductionBaseInsurance(person),
      baseInsurance: person.hasBaseInsurance ? {
        baseInsuranceRate: `/api/base-insurance-rates/${person.baseInsurance?.rate?.id}`,
        oldBaseInsuranceRate: `/api/base-insurance-rates/${person.baseInsurance?.oldRate?.id}`,
        start: startOfNextYear.format(Config.apiDateFormat),
        accidentCoverage: person?.baseInsurance?.withAccident || false,
      } : undefined,
      moveInFromAbroad: person.moveInFromAbroad,
      dateOfArrival: null,
      childPremiumReduction: false,
    };
  }

  getInsuranceData(): IApiInsurance {
    return {
      people: this.models.map((person, index) => this.convertPersonToIPersonRequest(person, index, true)),
    };
  }

  getIssueRequest(): IIssue {
    return {
      issueType: Config.issueTypeFranchiseChange,
      sex: this.policyHolder?.sex || null,
      firstName: this.policyHolder?.firstName || '',
      lastName: this.policyHolder?.lastName || '',
      email: this.email || '',
      insurances: [{
        start: startOfNextYear.format(Config.apiDateFormat),
        iban: this.iban || '',
        people: this.models.map((person, index) => this.convertPersonToIPersonRequest(person, index, false)),
      }],
    };
  }

  get hasBaseInsurance(): boolean {
    return this.models.some((p) => p.hasBaseInsurance);
  }

  getPremiumReductionBaseInsurance(person: Person): boolean {
    return this.children.indexOf(person) >= 2 && this.models.some((p) => !p.isChild);
  }
}
