import dayjs from 'dayjs';

export const isFranchiseChangeDisabled = () => {
  const currentMonth = dayjs().get('month');
  const disabledMonths = [11, 0]; // December and January

  return disabledMonths.includes(currentMonth);
};

export const isCancellationDisabled = () => {
  const currentMonth = dayjs().get('month');
  const disabledMonths = [11, 0]; // December and January

  return disabledMonths.includes(currentMonth);
};

const disabledYear = () => {
  const currentYear = dayjs().get('year');
  const currentMonth = dayjs().get('month');

  if (currentMonth === 0) {
    // in january, we are inside a "disabled" cycle, which started in december of the previous year,
    // so we need to use the previous year
    return currentYear - 1;
  }

  // from february until november (inclusive) we don't need this, so we can just return the current year
  // in december, we are inside a "disabled" cycle, which started in the beginning of december of the current year,
  // so we need to use the current year
  return currentYear;
};

const enabledYear = () => {
  const currentYear = dayjs().get('year');
  const currentMonth = dayjs().get('month');

  if (currentMonth === 0) {
    // in january, we are inside a "disabled" cycle, which will end at the end of january of the current year,
    // so we need to use the current year
    return currentYear;
  }

  // from february until november (inclusive) we don't need this, so we can just return the next year
  // in december, we are inside a "disabled" cycle, which will end at the end of january of the next year,
  // so we need to use the next year
  return currentYear + 1;
};

export const franchiseChangeDisabledDate = dayjs().month(10).date(30).year(disabledYear()); // November 30th
export const franchiseChangeEnabledDate = dayjs().month(1).date(1).year(enabledYear()); // February 1st of next year
export const cancellationDisabledDate = dayjs().month(10).date(30).year(disabledYear()); // November 30th
export const cancellationEnabledDate = dayjs().month(1).date(1).year(enabledYear()); // February 1st of next year
export const startOfNextYear = dayjs().startOf('year').add(1, 'year'); // January 1st of next year
