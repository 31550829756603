import {makeObservable, observable} from 'mobx';
import {IBaseModel} from './base-model';
import {PremiumVersion} from './premium-version';

export interface IBaseInsuranceRate extends IBaseModel {
  id: number;
  premiumVersionId: number;
  regionCode: string;
  baseInsuranceTypeId: number;
  franchiseGroupId: number;
  fromAge: number;
  toAge: number;
  valueWithoutAccident: number;
  valueWithAccident: number;
}

export class BaseInsuranceRate implements IBaseInsuranceRate {
  id = 0;
  premiumVersionId = 0;
  premiumVersion?: PremiumVersion = undefined;
  regionCode = '';
  baseInsuranceTypeId = 0;
  franchiseGroupId = 0;
  fromAge = 0;
  toAge = 0;
  valueWithoutAccident = 0;
  valueWithAccident = 0;

  constructor() {
    makeObservable(this, {
      id: observable,
      premiumVersionId: observable,
      regionCode: observable,
      baseInsuranceTypeId: observable,
      franchiseGroupId: observable,
      fromAge: observable,
      toAge: observable,
      valueWithoutAccident: observable,
      valueWithAccident: observable,
    });
  }

  public setPremiumVersion(premiumVersion?: PremiumVersion) {
    this.premiumVersion = premiumVersion;
  }

  static fromObject(data: IBaseInsuranceRate): BaseInsuranceRate {
    const baseInsuranceRate = new BaseInsuranceRate();
    baseInsuranceRate.id = data.id;
    baseInsuranceRate.premiumVersionId = data.premiumVersionId;
    baseInsuranceRate.regionCode = data.regionCode;
    baseInsuranceRate.baseInsuranceTypeId = data.baseInsuranceTypeId;
    baseInsuranceRate.franchiseGroupId = data.franchiseGroupId;
    baseInsuranceRate.fromAge = data.fromAge;
    baseInsuranceRate.toAge = data.toAge;
    baseInsuranceRate.valueWithoutAccident = data.valueWithoutAccident;
    baseInsuranceRate.valueWithAccident = data.valueWithAccident;

    return baseInsuranceRate;
  }
}
